
import { computed, defineComponent, onMounted, ref } from "vue";
import { onBeforeRouteLeave } from "vue-router";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import CardTitleJaya from "@/components/global/CardTitleJaya.vue";
import store from "@/store";
import { useRoute, useRouter } from "vue-router";
import { Modal } from "bootstrap";
import { Actions } from "@/store/enums/StoreEnums";
import { UserProject } from "@/store/modules/PrescriberModule";
import { getImageUrl } from "@/core/helpers/globalJaya";
import { openLinkAgriBest } from "@/core/helpers/globalJaya";

export default defineComponent({
  name: "homepage",
  components: {
    CardTitleJaya,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    let projectLinked = false;
    let project = ref<UserProject>({
      name: "",
      details: "",
      id: null,
      gdpr_purposes: [],
      project_purposes: [],
      prescriber: null,
      prescriber_charter: null,
      partners: "",
    });

    const openAgribestLink = () => {
      openLinkAgriBest(process.env.VUE_APP_ILLUSTRATED_URL);
    };

    const openModal = () => {
      const modalEl = document.getElementById("projectLink");
      const modal = new Modal(modalEl);
      modal.show();
    };
    const linkUser = () => {
      store
        .dispatch(Actions.ADD_USER_PROJECT, {
          project: project.value.id,
        })
        .then(() => {
          projectLinked = true;
          router
            .replace({ name: "homepage", query: { projectId: "" } })
            .then(() => {
              store
                .dispatch(Actions.CHECK_INDICATORS)
                .then((response) => {
                  if (response.exploitations.length) {
                    router.push({ name: "exploitations" });
                  } else {
                    router.push({ name: "my-projects" });
                  }
                })
                .catch(() => {
                  router.push({ name: "my-projects" });
                });
            });
        })
        .catch((e) => {
          openModal();
        });
    };
    onMounted(() => {
      setCurrentPageTitle("Accueil");
      const query = route.query;
      if (query.projectId) {
        store.dispatch(Actions.FETCH_USER_PROJECTS).then(() => {
          const userProjects = store.getters.getUserProjects;
          store
            .dispatch(Actions.GET_PROJECT_BY_UUID, query.projectId)
            .then((response) => {
              project.value = response;
              if (
                userProjects.filter((p) => p.id === response.id).length === 0
              ) {
                openModal();
              } else {
                router.replace({ name: "homepage", query: { projectId: "" } });
              }
            });
        });
      }
    });
    onBeforeRouteLeave((to, from, next) => {
      const query = route.query;
      if (query.projectId && projectLinked) {
        delete query.projectId;
        next({ path: to.path, query });
      }
      next();
    });
    const isOnline = computed(() => store.getters.isOnline);
    return {
      getImageUrl,
      isOnline,
      project,
      linkUser,
      openAgribestLink,
    };
  },
});
